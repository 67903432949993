import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`"Mimesis is a situation in which the relation between an organism and its environment are blurred and confused." Elizabeth Groz, Architecture from Outside.`}</p>
    <p>{`In this interactive experiment, I created a mirror that reflects more than one image of each person and show them in different time delays. It was interesting to see how people try to find their real one among their copies.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/01/mirror.jpg",
        "alt": "mirror"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=wXZrdGYmReQ&feature=emb_logo"
      }}>{`what if that was the only mirror we ever met`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      